/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Služby"}>
        <SiteHeader />

        <Column className="css-a8r9xz --style2 --left bg--bottom --full" name={"uvod"} style={{"backgroundColor":"var(--color-supplementary)"}} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/157/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/157/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/157/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/157/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/157/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/157/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/157/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/157/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1360}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3 pl--0" anim={"7"} animS={"3"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--86" style={{"maxWidth":""}} content={"<span style='color: white;'>Služby</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"sluzby"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--top" style={{"maxWidth":1080}} columns={"1"}>
            
            <ColumnWrapper className="--left mb--20">
              
              <Title className="title-box" content={"Tesařské práce"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, změnit velikost řádkování, zarovnat text na stranu a dokonce nastavit maximální šířku textu v pixlech."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left mb--20">
              
              <Title className="title-box" content={"Klempířské práce"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, změnit velikost řádkování, zarovnat text na stranu a dokonce nastavit maximální šířku textu v pixlech."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left mb--20">
              
              <Title className="title-box" content={"Pokrývačské práce"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, změnit velikost řádkování, zarovnat text na stranu a dokonce nastavit maximální šířku textu v pixlech."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-a8r9xz pb--80 pt--80" name={"kontakt"} parallax={false} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/157/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/157/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/157/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/157/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/157/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/157/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/157/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/157/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box" content={"<span style='color: white;'>Svěřte svůj domov do péče experta.</span>"}>
              </Title>

              <Button className="btn-box btn-box--pbtn4 btn-box--shape5 ff--2 fs--20 ls--002" use={"page"} page={"21897"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--26 swpf--uppercase" content={"Petr Domanický"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":334}} content={"Holandská 854/1, Brno<br>Česká Republika"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--26 swpf--uppercase" content={"kontakt"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":334}} content={"+420 797 811 2X0<br>email@saywebpage.com"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--right" style={{"maxWidth":304}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Text className="text-box text-box--right" style={{"maxWidth":304}} content={"<a href=\"https://saywebpage.com\">Tvorba web stránek</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}